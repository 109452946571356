import {DataService} from '../../helpers/fakebackend/data.service';

 const resData = JSON.parse(localStorage.getItem('resData'));
export const state = resData
    ? {status: {getDataSuccess: true}, resData}
    : {status: {}, resData: null};

export const actions = {

postData({dispatch, commit}, {key,data}) {
        return DataService.postDatabyKey(key ,data)
            .then(
                 (res) => {
                    commit('PotDataSuccess', res);
                    return res
                },
                error => {
                    commit('PostDataFailure', error);
                    dispatch('notification/error', error, {root: true});
                }
            );
    },



    putData({dispatch, commit},{ key , data}) {
        return DataService.putDatabyKey(key ,data)
            .then(
                 (res) => {
                    commit('PutDataSuccess', res);
                    return res
                },
                error => {
                    commit('PutDataFailure', error);
                    dispatch('notification/error', error, {root: true});
                }
            );
    },



    deleteData({dispatch, commit}, key) {
        return DataService.deleteDatabyKey(key)
            .then(
                 (res) => {
                    commit('DeleteDataSuccess', res);
                    return res
                },
                error => {
                    commit('DeleteDataFailure', error);
                    dispatch('notification/error', error, {root: true});
                }
            );
    },
    }
    
    export const mutations = {
    PostDataSuccess(state, resData) {
        state.status = {postDataSuccess: true};
        state.resData = resData;
    },
    PostDataFailure(state) {
        state.status = {};
        state.resData = null;
    },

    PutDataSuccess(state, resData) {
        state.status = {putDataSuccess: true};
        state.resData = resData;
    },
    PutDataFailure(state) {
        state.status = {};
        state.resData = null;
    },

    DeleteDataSuccess(state, resData) {
        state.status = {deleteDataSuccess: true};
        state.resData = resData;
    },
    DeleteDataFailure(state) {
        state.status = {};
        state.resData = null;
    },

};